
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.table-view, .group-view {
  .list-group {
    min-height: 50px;
    > span {
      display: block;
      min-height: 50px;
      padding: 20px;
    }
  }
}
